import React, { useEffect, useState, useContext } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AuthContext } from '../../context/AuthContext';
import useFirestoreCollection from '../../hooks/useFirestoreCollection';
import AddStoreModal from '../../components/popups/AddStoreModal';
import AddHomeIcon from '@mui/icons-material/AddHome';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import Tooltip from '@mui/material/Tooltip';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { capitalizeWords } from '../../utilities/customText';
import useManagerUsers from '../../hooks/useManagerUsers';
import useFirestoreUpdate from '../../hooks/useFirestoreUpdate';

const StoreManagement = () => {
  const [openAddStoreModal, setOpenAddStoreModal] = useState(false);
  const [cellModesModel, setCellModesModel] = React.useState({});
  const { loading, role } = useContext(AuthContext);
  const {
    data,
    loading: storeLoading,
    error,
  } = useFirestoreCollection('stores');
  const {
    data: users,
    loading: userLoading,
    error: userError,
  } = useManagerUsers();
  const newManager = useFirestoreUpdate();

  const handleAddStore = () => {
    setOpenAddStoreModal(true);
  };

  /**
   * component for custom descending icon
   * @returns component for custom descending icon
   */
  const SortedDescendingIcon = () => {
    return <ExpandMoreIcon className='icon' />;
  };

  /**
   * component for custom ascending icon
   * @returns component for custom ascending icon
   */
  const SortedAscendingIcon = () => {
    return <ExpandLessIcon className='icon' />;
  };

  /**
   * component for custom unsorted icon
   * @returns component for custom unsorted icon
   */
  const UnsortedIcon = () => {
    return <SortIcon className='icon' />;
  };

  if (loading || storeLoading || userLoading) return <CircularProgress />;
  if (error || userError)
    return (
      <Paper>
        {error && <Typography>{error}</Typography>}
        {userError && <Typography>{userError}</Typography>}
      </Paper>
    );
  // TODO update this to useContext
  if (role !== 'admin' && role !== 'maintainer') {
    return (
      <Grid
        container
        id='adminDashboard'
      >
        <Grid
          item
          xs
        >
          <Paper>
            <Typography>
              You don't have permissions to view this page.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  /**
   * writes changes to firestore
   * @param {Object} newRow the new data after changes were made in the data grid
   * @returns new object data after changes
   */
  const updateManager = (newRow) => {
    const workHoursPath = ['stores', newRow.id];

    newManager.upsertFirestoreDoc(workHoursPath, newRow);

    return newRow;
  };

  /**
   * Custom toolbar with custom table actions
   * @returns Data grid toolbar component
   */
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ justifyContent: 'space-between', px: 2, pt: 2 }}
      >
        <Tooltip title='Add new store'>
          <Button
            size='small'
            onClick={handleAddStore}
            startIcon={<AddHomeIcon />}
            variant='text'
          >
            Add Store
          </Button>
        </Tooltip>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  const gridProps = {
    columns: [
      {
        field: 'name',
        headerName: 'Active Stores',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: ({ value }) => {
          if (value) return capitalizeWords(value);
          return value;
        },
      },
      {
        field: 'manager',
        headerName: 'Store Manager',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        editable: true,
        type: 'singleSelect',
        valueOptions: users.map((user) => user.email),
      },
    ],
    initialState: {
      sorting: {
        sortModel: [
          {
            field: 'name',
            sort: 'asc',
          },
        ],
      },
    },
    slots: {
      toolbar: CustomToolbar,
      columnSortedDescendingIcon: SortedDescendingIcon,
      columnSortedAscendingIcon: SortedAscendingIcon,
      columnUnsortedIcon: UnsortedIcon,
    },
  };

  return (
    <Grid
      container
      id=''
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Paper>
          <DataGrid
            rows={data}
            {...gridProps}
            pageSize={5}
            loading={storeLoading}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection={false}
            onProcessRowUpdateError={(error) => {
              console.log('error while updating row', error);
            }}
            processRowUpdate={updateManager}
            cellModesModel={cellModesModel}
            onCellModesModelChange={(model) => setCellModesModel(model)}
          />
        </Paper>
      </Grid>
      <AddStoreModal
        open={openAddStoreModal}
        handleClose={() => {
          setOpenAddStoreModal(false);
        }}
      />
    </Grid>
  );
};

export default StoreManagement;
