import { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  // collection,
  query,
  onSnapshot,
  // doc,
} from 'firebase/firestore';
// import db from './path_to_your_firebase_config';
import { db } from '../firebase';

const useEmployees = (storeName) => {
  // const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const querySnapshot = await getDocs(collection(db, 'employees'));
  //     const data = [];
  //     querySnapshot.forEach((doc) => {
  //       data.push({
  //         id: doc.id,
  //         ...doc.data(),
  //       });
  //     });
  //     setRows(data);
  //     setLoading(false);
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    if (!storeName) return; // if provided store name blank, do nothing.
    
    const docRef = doc(db, `stores/${storeName}`);
    const subCollectionRef = collection(docRef, 'employees');
    const q = query(subCollectionRef);

    // Here we set up the real-time listener
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() });
        });
        setData(results);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    // Returning the unsubscribe function will ensure that the listener is turned off when the component using the hook is unmounted.
    return () => unsubscribe();
  }, [storeName]);

  const addEmployee = (firstName, lastName) => {
    const newEmployee = {
      firstName,
      lastName,
      // ... other fields with default values
    };
    const docRef = doc(collection(db, 'employees'));
    try {
      setDoc(docRef, newEmployee).then(() => {
        setData((prev) => [...prev, { id: docRef.id, ...newEmployee }]);
      });
    } catch (e) {
      setError(e);
    }
  };

  const deleteSelectedEmployees = (selectedIDs) => {
    selectedIDs.forEach(async (id) => {
      await deleteDoc(doc(db, 'employees', id));
    });
    setData((prev) => prev.filter((row) => !selectedIDs.includes(row.id)));
  };

  return { data, loading, error, addEmployee, deleteSelectedEmployees };
};

export default useEmployees;
