import React, { useEffect, useState } from 'react';
// mui components
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// custom hooks
import HoursFilterDatePicker from '../../components/inputs/HoursFilterDatePicker/HoursFilterDatePicker';
import useEmployeeHours from '../../hooks/useEmployeeHours4';
// custom components
import StoreSelect from '../../components/inputs/StoreSelect/StoreSelect';
import AddEmployeeModal from '../../components/popups/AddEmployeeModal';
import AddStoreModal from '../../components/popups/AddStoreModal';
import HoursEntryTable from '../../components/forms/HoursEntryTable/HoursEntryTable';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const HoursEntry = () => {
  const [openAddStoreModal, setOpenAddStoreModal] = useState(false);
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedDate, setSelectedDate] = useState();

  const { employeeHoursData, setWeekEndingDate, setStoreName, loading } =
    useEmployeeHours();

  /**
   * StoreSelect component callback
   * Sets the local state to the relevant store employees
   * @param {string} selectedOption the selected store
   */
  const handleStoreSelection = (selectedOption) => {
    console.log('StoreSelect ', selectedOption);
    setSelectedStore(selectedOption);
    setStoreName(selectedOption);
  };

  /**
   * StoreSelect component callback
   * Sets the local state to the relevant store employees
   * @param {string} selectedOption the selected store
   */
  const handleDateChange = (date) => {
    console.log('handleDateChanged callback', date);

    setWeekEndingDate(date.toISOString());
    setSelectedDate(date.format('MM/DD/YYYY'));
  };

  const handleAddEmployee = () => {
    setOpenAddEmployeeModal(true);
  };

  const handleAddStore = () => {
    setOpenAddStoreModal(true);
  };

  return (
    <Grid
      container
      id='hoursEntry'
      spacing={2}
    >
      <Grid
        item
        xs={6}
      >
        <Paper sx={{ p: 2, overflow: 'hidden' }}>
          <StoreSelect onOptionSelected={handleStoreSelection} />
        </Paper>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Paper sx={{ p: 2, overflow: 'hidden' }}>
          <HoursFilterDatePicker onOptionSelected={handleDateChange} />
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Paper sx={{ p: 2, overflow: 'hidden' }}>
          <Box>
            <Typography gutterBottom>Enter Week End Hours</Typography>
            <Typography
              variant='caption'
              display='block'
              sx={{ mb: 2 }}
              gutterBottom
            >
              List all employees to start entering work hours
            </Typography>
          </Box>
          <HoursEntryTable
            loading={loading}
            selectedDate={selectedDate}
            selectedStore={selectedStore}
            data={employeeHoursData}
          />
        </Paper>
      </Grid>
      <AddEmployeeModal
        open={openAddEmployeeModal}
        handleClose={() => {
          setOpenAddEmployeeModal(false);
        }}
      />
      <AddStoreModal
        open={openAddStoreModal}
        handleClose={() => {
          setOpenAddStoreModal(false);
        }}
      />
    </Grid>
  );
};

export default HoursEntry;
