import _ from 'lodash';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import TimerIcon from '@mui/icons-material/Timer';
import Store from '@mui/icons-material/Store';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LoginIcon from '@mui/icons-material/Login';
import LockResetIcon from '@mui/icons-material/LockReset';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

let routes = [
  // {
  //   id: 'Accounts',
  //   children: [
  //     {
  //       id: 'Sign In',
  //       icon: <LoginIcon />,
  //       active: false,
  //       href: '/login',
  //     },
  //     {
  //       id: 'Sign Up',
  //       icon: <PersonAddIcon />,
  //       active: false,
  //       href: '/sign-up',
  //     },
  //     {
  //       id: 'Reset Password',
  //       icon: <LockResetIcon />,
  //       active: false,
  //       href: '/reset-password',
  //     },
  //   ],
  // },
  {
    id: 'Admin',
    children: [
      {
        id: 'User Management',
        icon: <PeopleIcon />,
        active: false,
        href: '/user-management',
      },
      {
        id: 'Store Management',
        icon: <Store />,
        active: false,
        href: '/store-management',
      },
    ],
  },
  {
    id: 'Tracking',
    children: [
      {
        id: 'Hours Entry',
        icon: <TimerIcon />,
        active: false,
        href: '/hours-entry',
      },
      {
        id: 'Insurance Tracking',
        icon: <DnsRoundedIcon />,
        active: false,
        href: '/insurance-tracking',
      },
      {
        id: 'Employees',
        icon: <PeopleIcon />,
        active: false,
        href: '/employee-management',
      },
    ],
  },
];

// sort routes in alpha order
routes = _.chain(routes)
  .sortBy(['id'])
  .map((item) => {
    return {
      ...item,
      children: _.sortBy(item.children, ['id']),
    };
  })
  .value();

export default routes;
