import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import SiteNav from './SiteNav';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import { theme } from '../../theme/Theme';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Footer from './Footer';
import CircularProgress from '@mui/material/CircularProgress';

const drawerWidth = 250;

export default function Layout({ loading }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Header
        onDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <SiteNav drawerWidth={drawerWidth} />
      <Grid
        container
        component='main'
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        sx={{
          width: '100%',
          height: 'calc(100vh - 57px)',
          bgcolor: 'rgb(240, 242, 245)',
          p: 2,
          mt: '57px',
        }}
      >
        {!loading ? <Outlet /> : <CircularProgress />}
      </Grid>
      {/* <Footer drawerWidth={drawerWidth} /> */}
    </Box>
  );
}
