import { useState, useEffect } from 'react';
import _ from 'lodash';
import { db } from '../firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

function useManagerUsers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const q = query(
      collection(db, 'users'),
      where('role', '==', 'manager'),
      where('approved', '==', true)
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ id: doc.id, ...doc.data() });
        });
        setData(_.orderBy(docs, 'email'));
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { data, loading, error };
}

export default useManagerUsers;
