import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import routes from '../../routes/Routes';
import { useLocation } from 'react-router-dom';
import { Home } from '@mui/icons-material';

export default function SiteNav({ drawerWidth }) {
  let location = useLocation();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
        bgcolor: 'primary.main',
      }}
      variant='permanent'
      anchor='left'
      id='siteNav'
      hideBackdrop
      PaperProps={{
        sx: { bgcolor: 'primary.main' },
      }}
    >
      <List disablePadding>
        <ListItemButton
          key={Home}
          sx={{
            boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
            py: 1.5,
            px: 3,
            fontSize: 22,
            borderRight: '1px solid #fff',
          }}
          component='a'
          href='/hours-entry'
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          MCD
        </ListItemButton>
        <Divider />
        {routes.map(({ id, children }) => (
          <Box
            key={id}
            sx={{ bgcolor: 'primary.main' }}
          >
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, href }) => (
              <ListItem
                disablePadding
                key={childId}
              >
                <ListItemButton
                  selected={location.pathname === href}
                  component='a'
                  href={href}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
