import { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, onSnapshot, doc } from 'firebase/firestore';

function useEmployees(storeName) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // if (!storeName) return;

  useEffect(() => {
    const docRef = doc(db, `stores/${storeName}`);
    const subCollectionRef = collection(docRef, 'employees');
    const q = query(subCollectionRef);

    // Here we set up the real-time listener
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() });
        });
        setData(results);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    // Returning the unsubscribe function will ensure that the listener is turned off when the component using the hook is unmounted.
    return () => unsubscribe();
  }, [storeName]);

  return { data, loading, error };
}

export default useEmployees;
