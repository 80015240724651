import { useState, useEffect, useContext } from 'react';
// import { auth } from '../../../../firebase';
import Layout from './Default/Default';
import NotAdmin from './NotAdmin';
// import { onAuthStateChanged } from 'firebase/auth';
import { AuthContext } from '../context/AuthContext';
// const { email } = useContext(AuthContext);

function AdminRoutes() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const { role } = useContext(AuthContext);

  useEffect(() => {
    console.log('adminRoutes useEffect trigger', role);
    setLoading(true);
    if (role && (role === 'admin' || role === 'maintainer')) {
      setIsAdmin(true);
      setLoading(false);
    } else {
      setIsAdmin(false);
      setLoading(false);
    }
  }, []);

  if (!isAdmin) return <NotAdmin loading={loading} />;

  return <Layout loading={loading} />;
}

export default AdminRoutes;
