import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import useFirestoreCollection from '../../../hooks/useFirestoreCollection';

import { capitalizeWords } from '../../../utilities/customText';

function StoreSelect({ onOptionSelected, defaultValue }) {
  const { data: options, loading, error } = useFirestoreCollection('stores');
  const [selected, setSelected] = useState(defaultValue ? defaultValue : '');

  const handleChange = (event) => {
    console.log('storeSelect handleChange', event.target.value);
    const selectedValue = event.target.value;
    setSelected(selectedValue);
    if (onOptionSelected) {
      console.log('storeSelected onOptionsSelected');
      onOptionSelected(selectedValue);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity='error'>Error: {error.message}</Alert>;

  return (
    <FormControl
      fullWidth
      size='small'
    >
      <InputLabel htmlFor='firestore-select'>Select Store</InputLabel>
      <Select
        labelId='demo-simple-select-outlined-label'
        value={selected}
        onChange={handleChange}
        label='Select Option'
        size='small'
      >
        {options.length > 0 ? (
          options.map((option) => (
            <MenuItem
              key={option.name}
              value={option.name}
            >
              {capitalizeWords(option.name)}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            key={0}
            value={0}
          >
            No Options. Please add a store.
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default StoreSelect;
