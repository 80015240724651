import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import EmployeeTable from '../../components/tables/EmployeeTable';

const EmployeeManagement = () => {
  return (
    <Grid
      container
      id='employeeManagement'
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Paper sx={{ p: 2, overflow: 'hidden' }}>
          <EmployeeTable />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EmployeeManagement;
