import dayjs from 'dayjs';

/**
 * gets the most recent Wednesday date
 * @returns dayjs object for the closet Wednesday
 */
export const getPreviousWednesday = () => {
  let day = dayjs();

  while (day.day() !== 3) {
    // 3 represents Wednesday
    day = day.subtract(3, 'day');
  }

  return day;
};

/**
 * Checks if the date passed in is a Wednesday
 * @param {object} date dayjs object for XX date
 * @returns boolean value if the day of the week is a Wednesday (3) or not
 */
export const isWednesday = (date) => {
  if (typeof date == 'string') return dayjs(date).day() === 2;
  return date.day() === 2;
  // return date.day() === 0 && dayjs().diff(date, 'day') < 30;
  // return dayjs(date).day() === 6;
};
