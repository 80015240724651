import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../../firebase';
import Alert from '@mui/material/Alert';

import useAuth from '../../hooks/useAuth';
import { redirect } from 'react-router-dom';
import useLogin from '../../hooks/useLogin';

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link
        color='inherit'
        href='#'
      >
        cirept.media
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const SignIn = () => {
  const navigate = useNavigate();
  const { loginUser, loading, error } = useLogin();

  // check if user already logged in.
  const user = useAuth();
  console.log('signin user', user.currentUser);
  // redirect user to home page is already logged in.
  if (user.currentUser) window.location.href = '/hours-entry';

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .then(() => navigate('/hours-entry'))
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('submit clicked');
      // signInWithEmailAndPassword(auth, values.email, values.password)
      //   .then((userCredential) => {
      //     // Sign in success
      //     const user = userCredential.user;
      //     console.log(user);
      //   })
      //   .then(() => navigate("/hours-entry"))
      //   .catch((error) => {
      //     // Handle errors here.
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     console.log(errorCode, errorMessage);
      //   });
      const result = await loginUser(values.email, values.password);
      if (result.success) {
        // Proceed with the app's post-login flow
        //     // Sign in success
        // const user = userCredential.user;
        // console.log(user);
        navigate('/hours-entry');
      } else {
        // Handle the error (optional since we already set the error in the hook)
      }
    },
  });

  return (
    <Grid
      container
      component='main'
      sx={{ height: '100vh' }}
    >
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component='h1'
            variant='h5'
          >
            Sign in
          </Typography>
          <Box
            component='form'
            onSubmit={formik.handleSubmit}
            sx={{ mt: 3 }}
          >
            <TextField
              fullWidth
              required
              id='email'
              name='email'
              label='Username'
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              required
              type='password'
              id='password'
              name='password'
              label='Password'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name='remember'
                  color='primary'
                  checked={formik.values.remember}
                  onChange={formik.handleChange}
                />
              }
              label='Remember me'
              sx={{ mt: 2 }}
            />
            <Button
              color='primary'
              variant='contained'
              fullWidth
              type='submit'
              sx={{ mt: 2 }}
              disabled={loading}
            >
              Sign In
            </Button>
            {/* <Button
                fullWidth
                variant='outlined'
                startIcon={<GoogleIcon />}
                sx={{ mt: 2 }}
                onClick={handleGoogleSignIn}
              >
                Sign in with Google
              </Button> */}
            <Box
              sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <Link href='/reset-password'>Forgot password?</Link>
              <Link href='/sign-up'>Don't have an account? Sign Up</Link>
            </Box>
            {error && (
              <Box sx={{ mt: 2 }}>
                <Alert severity='warning'>{error}</Alert>
              </Box>
            )}
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignIn;
