import { createTheme } from '@mui/material/styles';

export let lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#E41C34' }, // McDonald's Red
    secondary: { main: '#FFC300' }, // McDonald's Yellow
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

lightTheme = {
  ...lightTheme,
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.8)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#E41C34',
          color: '#fff',
          padding: `2px ${lightTheme.spacing(3)}`,
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.35)',
            color: '#E41C34',
          },
          '&:hover, &:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.35)',
            color: '#E41C34',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
  },
};

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#E41C34' },
    secondary: { main: '#FFC300' },
    background: { default: '#181818', paper: '#212121' },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
          '&:hover, &:focus': {
            bgcolor: 'rgba(255, 255, 255, 0.7)',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
          '&:hover, &:focus': {
            bgcolor: 'rgba(255, 255, 255, 0.7)',
          },
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});
