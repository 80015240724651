import React, { useState, useContext, useEffect } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { Button, Tooltip, Box, Grid } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import useEmployees from '../../hooks/useEmployees2';
import AddEmployeeModal from '../popups/AddEmployeeModal';
import { capitalizeWords } from '../../utilities/customText';
import useFirestoreUpdate from '../../hooks/useFirestoreUpdate';
import _ from 'lodash';
import StoreSelect from '../inputs/StoreSelect/StoreSelect';

const EmployeeTable = () => {
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState('');
  const update = useFirestoreUpdate();
  const { data } = useEmployees(selectedStore);

  /**
   * Open 'Add Employee' modal
   */
  const handleAddEmployee = () => {
    setOpenAddEmployeeModal(true);
  };

  /**
   * Write workHours to db and return old data
   * @param {Object} newRow the updated value from the data grid
   * @returns the new row data to display
   */
  const updateName = (newRow) => {
    const workHoursPath = ['stores', selectedStore, 'employees', newRow.id];
    update.upsertFirestoreDoc(workHoursPath, newRow);
    return newRow;
  };

  /**
   * component for custom descending icon
   * @returns component for custom descending icon
   */
  const SortedDescendingIcon = () => {
    return <ExpandMoreIcon className='icon' />;
  };

  /**
   * component for custom ascending icon
   * @returns component for custom ascending icon
   */
  const SortedAscendingIcon = () => {
    return <ExpandLessIcon className='icon' />;
  };

  /**
   * component for custom unsorted icon
   * @returns component for custom unsorted icon
   */
  const UnsortedIcon = () => {
    return <SortIcon className='icon' />;
  };

  /**
   * StoreSelect component callback
   * Sets the local state to the relevant store employees
   * @param {string} selectedOption the selected store
   */
  const handleStoreSelection = (selectedOption) => {
    console.log('EmployeeManagement StoreSelect ', selectedOption);
    setSelectedStore(selectedOption);
  };

  /**
   * custom grid toolbar
   * @returns custom grid toolbar component
   */
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'flex-start', px: 2, pt: 2 }}>
        <Grid
          item
          xs={2}
        >
          <StoreSelect onOptionSelected={handleStoreSelection} />
        </Grid>
        <Grid
          item
          xs={1}
        >
          <Tooltip title='Add new employee'>
            <Button
              size='small'
              onClick={handleAddEmployee}
              startIcon={<PersonAddAlt1Icon />}
              variant='text'
            >
              Add Employee
            </Button>
          </Tooltip>
        </Grid>
        <Grid
          container
          item
          xs
          sx={{ alignSelf: 'flex-end', justifyContent: 'flex-end' }}
        >
          <GridToolbarQuickFilter />
        </Grid>
      </GridToolbarContainer>
    );
  };

  return (
    <Box style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        rows={
          data && data.length > 0
            ? _.orderBy(data, ['lastName', 'firstName'], ['asc', 'asc'])
            : []
        }
        onProcessRowUpdateError={(error) => {
          console.log('error while updating row', error);
        }}
        processRowUpdate={updateName}
        columns={[
          {
            field: 'firstName',
            headerName: 'First Name',
            minWidth: 250,
            maxWidth: 350,
            flex: 1,
            editable: true,
            valueGetter: ({ value }) => {
              // capitalize first letter
              if (value) return capitalizeWords(value);
              return value;
            },
          },
          {
            field: 'lastName',
            headerName: 'Last Name',
            minWidth: 250,
            maxWidth: 350,
            flex: 1,
            editable: true,
            valueGetter: ({ value }) => {
              // capitalize first letter
              if (value) return capitalizeWords(value);
              return value;
            },
          },
        ]}
        slots={{
          toolbar: CustomToolbar,
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
        }}
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'lastName',
                sort: 'asc',
              },
            ],
          },
        }}
        checkboxSelection
      />
      <AddEmployeeModal
        open={openAddEmployeeModal}
        handleClose={() => {
          setOpenAddEmployeeModal(false);
        }}
        store={selectedStore}
      />
    </Box>
  );
};

export default EmployeeTable;
