import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import routes from "../../routes/Routes";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { auth } from "../../firebase";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import Tooltip from "@mui/material/Tooltip";

const lightColor = "rgba(255, 255, 255, 0.7)";

function Header({ onDrawerToggle, drawerWidth }) {
  let location = useLocation();

  /**
   * Find the route ID for the current location in the app
   * @returns {string} route ID of current location based on URl
   */
  const getPageId = () => {
    let pageName;
    _.forEach(routes, function (navGroup) {
      _.forEach(navGroup.children, function (navItem) {
        // console.log("navItem", navItem);
        if (navItem.href === location.pathname) pageName = navItem.id;
      });
    });
    return pageName;
  };

  /**
   * Signs the user out
   */
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      console.log("user signed out");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <AppBar
      color="primary"
      // position="sticky"
      elevation={0}
      // sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      position="fixed"
      sx={{
        height: "57px",
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
      }}
    >
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h5" component="h1">
              {getPageId()}
            </Typography>
          </Grid>
          {/* <Grid item>
            <Tooltip title="Help">
              <IconButton color="inherit">
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </Grid> */}
          <Grid item>
            <Tooltip title="Alerts • No alerts">
              <IconButton color="inherit">
                <NotificationsIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Sign Out">
              <IconButton
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={handleSignOut}
              >
                {/* <Avatar
                src='/static/images/avatar/1.jpg'
                alt='My Avatar'
              /> */}
                <ExitToAppIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
