// import firebase from "firebase/app";
import { initializeApp } from "firebase/app";
// import "firebase/auth";
import { getAuth } from "firebase/auth";
// import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxy7KEhiP40Mehs78A1UBDowH_RX2DL_M",
  authDomain: "project-mickiedees.firebaseapp.com",
  databaseURL: "https://project-mickiedees-default-rtdb.firebaseio.com",
  projectId: "project-mickiedees",
  storageBucket: "project-mickiedees.appspot.com",
  messagingSenderId: "63063171860",
  appId: "1:63063171860:web:54a3382383036395df9801",
  measurementId: "G-VBPJCL3GG2",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
// export const db = firebase.firestore();
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
