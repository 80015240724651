import { useState } from 'react';
import {
  collection,
  doc,
  addDoc,
  setDoc,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';

import { createStoreObject } from '../utilities/createObject';
import { db } from '../firebase';

function useFirestoreUpdate() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * insert or updates data in firestore depending if docPath exists
   * @param {Array} docPath the collection path, can be a root collection of sub-collection
   * @param {Object} updatedData object containing the data to add to firestore collection
   */
  const upsertFirestoreDoc = async (docPath, updatedData) => {
    setLoading(true);
    setError(null);

    console.log('upsertFirestoreDoc triggered docPath', docPath);
    console.log('upsertFirestoreDoc triggered updatedData', updatedData);

    try {
      const documentRef = doc(db, ...docPath);

      // Create a new object that contains the updated data and the createdOn timestamp
      // const newData = createStoreObject(updatedData);

      // console.log("created store object", newData);

      const dataToSet = {
        ...updatedData,
        lastModified: serverTimestamp(),
      };

      console.log('created dataToSet object', dataToSet);

      // Using setDoc with { merge: true } will either create the document if it doesn't exist
      // or merge the fields if it does. The createdOn field will only be set when the document is created.
      await setDoc(documentRef, dataToSet, { merge: true });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Creates a new document in the collectionPath
   * @param {Array} collectionPath the path to the collection to create the document
   * @param {Object} data the data for the document to create
   */
  const addFirestoreDoc = async (collectionPath, data) => {
    setLoading(true);
    setError(null);

    try {
      const newDocData = {
        ...data,
        lastModified: serverTimestamp(),
      };

      const collectionRef = collection(db, ...collectionPath);
      await addDoc(collectionRef, newDocData);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { addFirestoreDoc, upsertFirestoreDoc, loading, error };
}

export default useFirestoreUpdate;
