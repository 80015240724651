import { useEffect, useState, useContext } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
// import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// import CircularProgress from "@mui/icons-material/CircularProgress";
import CircularProgress from '@mui/material/CircularProgress';
// import CircularProgress from '@mui/material/CircularProgress';

import useFirestoreUpdate from '../../hooks/useFirestoreUpdate';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import SortIcon from '@mui/icons-material/Sort';
import { AuthContext } from '../../context/AuthContext';

const PendingUsersTable = ({ loading, users }) => {
  //   const [users, setPendingUsers] = useState([]);
  const { email } = useContext(AuthContext);

  const columns = [
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'reviewed', headerName: 'Reviewed', width: 200 },
    { field: 'approved', headerName: 'Approved', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 300,
      renderCell: (params) => (
        <Stack
          direction='row'
          divider={
            <Divider
              orientation='vertical'
              flexItem
            />
          }
          spacing={2}
        >
          <Button
            variant='contained'
            color='success'
            onClick={() => handleApprove(params.row.id)}
            startIcon={<CheckIcon />}
            sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            Approve
          </Button>
          <Button
            variant='outlined'
            color='error'
            onClick={() => handleReject(params.row.id)}
            startIcon={<CloseIcon />}
          >
            Reject
          </Button>
        </Stack>
      ),
    },
  ];

  const updateUser = useFirestoreUpdate();

  const handleApprove = (userId) => {
    updateUser.upsertFirestoreDoc(['users', userId], {
      reviewed: true,
      approved: true,
      reviewedBy: email,
    });
    // Implement the logic to approve the user
  };

  const handleReject = (userId) => {
    updateUser.upsertFirestoreDoc(['users', userId], {
      reviewed: true,
      approved: false,
      reviewedBy: email,
    });
    // Implement the logic to reject (or possibly delete) the user
  };

  /**
   * component for custom descending icon
   * @returns component for custom descending icon
   */
  const SortedDescendingIcon = () => {
    return <ExpandMoreIcon className='icon' />;
  };

  /**
   * component for custom ascending icon
   * @returns component for custom ascending icon
   */
  const SortedAscendingIcon = () => {
    return <ExpandLessIcon className='icon' />;
  };

  /**
   * component for custom unsorted icon
   * @returns component for custom unsorted icon
   */
  const UnsortedIcon = () => {
    return <SortIcon className='icon' />;
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <DataGrid
      rows={users}
      columns={columns}
      pageSize={5}
      autoHeight
      loading={loading}
      rowsPerPageOptions={[5, 10, 20]}
      checkboxSelection={false}
      slots={{
        columnSortedDescendingIcon: SortedDescendingIcon,
        columnSortedAscendingIcon: SortedAscendingIcon,
        columnUnsortedIcon: UnsortedIcon,
        noRowsOverlay: () => (
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            No Pending User Approvals
          </Box>
        ),
      }}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'email',
              sort: 'asc',
            },
          ],
        },
      }}
    />
  );
};

export default PendingUsersTable;
