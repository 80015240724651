import { useEffect, useState, useContext } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import SortIcon from '@mui/icons-material/Sort';
import { AuthContext } from '../../context/AuthContext';
import useFirestoreUpdate from '../../hooks/useFirestoreUpdate';
import { Timestamp } from 'firebase/firestore';

const ReviewedUsersTable = ({ loading, users }) => {
  const { role } = useContext(AuthContext);
  const updateEmp = useFirestoreUpdate();

  const columns = [
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'reviewed',
      editable: role === 'admin' ? true : false,
      type: 'boolean',
      headerName: 'Reviewed',
      width: 200,
    },
    {
      field: 'approved',
      editable: role === 'admin' ? true : false,
      type: 'boolean',
      headerName: 'Approved',
      width: 200,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 200,
      editable: role === 'admin' || role === 'maintainer' ? true : false,
      type: 'singleSelect',
      valueOptions: ['maintainer', 'manager'],
    },
    {
      field: 'reviewedBy',
      headerName: 'Reviewed By',
      width: 200,
      editable: role === 'admin' ? true : false,
    },
  ];

  /**
   * component for custom descending icon
   * @returns component for custom descending icon
   */
  const SortedDescendingIcon = () => {
    return <ExpandMoreIcon className='icon' />;
  };

  /**
   * component for custom ascending icon
   * @returns component for custom ascending icon
   */
  const SortedAscendingIcon = () => {
    return <ExpandLessIcon className='icon' />;
  };

  /**
   * component for custom unsorted icon
   * @returns component for custom unsorted icon
   */
  const UnsortedIcon = () => {
    return <SortIcon className='icon' />;
  };

  /**
   * Write updates to db and return new data
   * @param {Object} newRow the updated value from the data grid
   * @returns the new row data to display
   */
  const updateHours = (newRow) => {
    const empPath = ['users', newRow.id];

    updateEmp.upsertFirestoreDoc(empPath, newRow);

    return newRow;
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <DataGrid
      disableRowSelectionOnClick
      autoHeight
      isCellEditable={(params) => {
        // rows are only edittable if user is not an admin and is approved
        return params.row.approved && !(params.row.role === 'admin');
      }}
      rows={users}
      columns={columns}
      pageSize={5}
      loading={loading}
      rowsPerPageOptions={[5, 10, 20]}
      checkboxSelection={false}
      onProcessRowUpdateError={(error) => {
        console.log('error while updating row', error);
      }}
      processRowUpdate={updateHours}
      slots={{
        columnSortedDescendingIcon: SortedDescendingIcon,
        columnSortedAscendingIcon: SortedAscendingIcon,
        columnUnsortedIcon: UnsortedIcon,
      }}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'email',
              sort: 'asc',
            },
          ],
        },
      }}
    />
  );
};

export default ReviewedUsersTable;
