import React, { useState, useEffect } from 'react';
import { Modal, TextField, Button, Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useFirestoreUpdate from '../../hooks/useFirestoreUpdate';

export default function AddStoreModal({ open, handleClose, store }) {
  const [item, setItem] = useState({
    name: '',
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const { upsertFirestoreDoc, loading, error } = useFirestoreUpdate();

  const handleInputChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const handleCloseModal = () => {
    setShowSuccess(false);
    handleClose(item);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('form submitting');
    const docRef = await upsertFirestoreDoc(['stores', item.name], item);
    // there won't be anything returned if the update was successfull
    // however, if there was an error something will be returned
    if (error) {
      console.log('data not saved', docRef.error);
    }
    setShowSuccess(true);
    setItem(''); // Reset the input field
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
    >
      <Stack
        component='form'
        onClick={handleSubmit}
        spacing={2}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          /* boxShadow: 24, */
          p: 2,
          width: 500,
        }}
      >
        <Typography variant='h6'>Enter Store Details</Typography>
        <TextField
          name='name'
          label='Store Name'
          fullWidth
          margin='normal'
          size='small'
          onChange={handleInputChange}
        />
        {!showSuccess && !loading && !error && (
          <Button
            variant='contained'
            color='primary'
          >
            Save
          </Button>
        )}
        {loading && <p>Submitting...</p>}
        {error && <p>Error: {error.message}</p>}
        {showSuccess && (
          <Box sx={{ mt: 1 }}>
            <Alert
              variant='outlined'
              severity='success'
            >
              Store created!
            </Alert>
          </Box>
        )}
      </Stack>
    </Modal>
  );
}
