import { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import _ from "lodash";

const useUserReview = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [reviewedUsers, setReviewedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const colRef = collection(db, "users");
    const unsub = onSnapshot(
      colRef,
      (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ id: doc.id, ...doc.data() });
        });
        console.log("users", docs);

        const splitUsers = _.partition(docs, (o) => !o.reviewed);

        setPendingUsers(splitUsers[0]);
        setReviewedUsers(splitUsers[1]);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      },
    );

    return () => unsub();
  }, []);

  return { pendingUsers, loading, reviewedUsers, error };
};

export default useUserReview;
