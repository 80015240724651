import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loginUser = async (email, password) => {
    setLoading(true);
    setError(null);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      //   console.log("useCredential", userCredential.user);
      const uid = userCredential.user.uid;

      // Check if the user is verified
      const userRef = doc(db, "users", uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      if (userData && userData.verified) {
        // User is verified, proceed
        setLoading(false);
        return { success: true };
      } else {
        // Log out the user immediately since they are not verified
        auth.signOut();
        setLoading(false);
        setError(
          "Your account has not been verified yet! Contact a System Administrator.",
        );
        return { success: false };
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
      return { success: false };
    }
  };

  return { loginUser, loading, error };
};

export default useLogin;
