import React, { useState, useEffect, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { auth } from './firebase';
import SignIn from './pages/authentication/SignIn';
import Layout from './layouts/Default/Default';
import HoursEntry from './pages/manager/HoursEntry';
import InsuranceTracking from './pages/manager/InsuranceTracking';
import ResetPassword from './pages/authentication/ResetPassword';
import SignUp from './pages/authentication/SignUp';
import EmployeeManagement from './pages/manager/EmployeeManagement';
// import WeatherDataGrid from "./layouts/user/pages/weatherData";
// import DisplayEmployeeHours from "./layouts/user/maintainer/pages/employeeHours";
// import { ThemeContext } from "./context/ThemeContext";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { theme } from './theme/Theme';
import { lightTheme, darkTheme } from './theme/Theme2';
import { ThemeContext, ThemeProvider } from './context/ThemeContext2';
import CssBaseline from '@mui/material/CssBaseline';

import ProtectedRoutes from './layouts/ProtectedRoutes';
import AdminRoutes from './layouts/AdminRoutes';
import UserManagement from './pages/maintainer/UserManagement';
import StoreManagemnet from './pages/maintainer/StoreManagement';
import { AuthProvider } from './context/AuthContext';

export default function App() {
  return (
    <MUIThemeProvider theme={lightTheme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route
                path='/hours-entry'
                element={<HoursEntry />}
              />
              <Route
                path='/insurance-tracking'
                element={<InsuranceTracking />}
              />
              <Route
                path='/employee-management'
                element={<EmployeeManagement />}
              />
            </Route>
            <Route element={<AdminRoutes />}>
              <Route
                path='/user-management'
                element={<UserManagement />}
              />
              <Route
                path='/store-management'
                element={<StoreManagemnet />}
              />
            </Route>
            <Route
              path='/login'
              element={<SignIn />}
            />
            <Route
              path='/reset-password'
              element={<ResetPassword />}
            />
            <Route
              path='/sign-up'
              element={<SignUp />}
            />
            <Route
              path='/'
              element={<SignIn />}
            />
          </Routes>
        </Router>
      </AuthProvider>
    </MUIThemeProvider>
  );
}
