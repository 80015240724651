import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('useAuth hook', auth);

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        // console.log('currentUser', currentUser);
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        // console.log('userDoc', userDoc.data());
        if (userDoc.exists()) {
          setDetails(userDoc.data());
        }
      } else {
        setUser(null);
        setDetails(null);
      }
      setLoading(false);
    });

    // Cleanup the subscription on unmount
    return () => unsubscribe();
  }, []);

  return { user, loading, details };
};

export default useAuth;
