import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import useAddToCollection from '../../utilities/hooks/firestore/useAddToCollection';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// import MyPaper from '../../surfaces/Paper/Paper';

import StoreSelect from '../inputs/StoreSelect/StoreSelect';

import useFirestoreUpdate from '../../hooks/useFirestoreUpdate';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  store: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Required'),
});

export default function AddEmployeeModal({ open, handleClose, store }) {
  const [item, setItem] = useState({});
  const firstNameRef = useRef(null);

  console.log('AddEmployeeModal store', store);
  const [showSuccess, setShowSuccess] = useState(false);
  // const { addDocument, loading, error } = useAddToCollection();
  const { addFirestoreDoc, loading, error } = useFirestoreUpdate();
  // const [selectedStore, setSelectedStore] = useState("");

  // const handleInputChange = (e) => {
  //   setItem({ ...item, [e.target.name]: e.target.value });
  // };

  const handleCloseModal = () => {
    setShowSuccess(false);
    handleClose(item);
  };

  const handleSubmit = async (values) => {
    // e.preventDefault();
    console.log('form submitting', values);
    const docRef = await addFirestoreDoc(
      ['stores', values.store, 'employees'],
      values
    );
    // there won't be anything returned if the update was successfull
    // however, if there was an error something will be returned
    if (error) {
      console.log('data not saved', docRef.error);
    }
    console.log('Document upsert complete');
    setShowSuccess(true);
    // setItem(''); // Reset the input field
  };

  /**
   * StoreSelect component callback
   * Sets the local state to the relevant store employees
   * @param {string} selectedOption the selected store
   */
  const handleStoreSelection = (selectedOption) => {
    console.log('StoreSelect ', selectedOption);
    // setSelectedStore(selectedOption);
    setItem({ ...item, store: selectedOption });
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
    >
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='stretch'
        spacing={2}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          /* boxShadow: 24, */
          p: 2,
          width: 500,
          textAlign: 'center',
        }}
      >
        <Typography
          component='h2'
          variant={'h5'}
        >
          Enter Employee Details
        </Typography>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            store: store ? store : '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log('form submit triggered', values);
            handleSubmit(values);
          }}
        >
          {({ isSubmitting, errors, touched, resetForm }) => (
            <Form>
              <Box mb={2}>
                <Field
                  name='firstName'
                  as={TextField}
                  variant='outlined'
                  fullWidth
                  label='First Name'
                  error={errors.firstName && touched.firstName}
                  helperText={<ErrorMessage name='firstName' />}
                  inputRef={firstNameRef}
                />
              </Box>
              <Box mb={2}>
                <Field
                  name='lastName'
                  as={TextField}
                  variant='outlined'
                  fullWidth
                  label='Last Name'
                  error={errors.lastName && touched.lastName}
                  helperText={<ErrorMessage name='lastName' />}
                />
              </Box>
              <Box mb={2}>
                <Field
                  name='store'
                  as={TextField}
                  variant='outlined'
                  disabled
                  fullWidth
                  label='Store Name'
                  error={errors.store && touched.store}
                  helperText={<ErrorMessage name='store' />}
                />
              </Box>
              {/* <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                disabled={isSubmitting}
              >
                Submit
              </Button> */}
              {!showSuccess && !loading && !error && (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={loading}
                  type='submit'
                  fullWidth
                  size={'large'}
                >
                  Save
                </Button>
              )}
              {loading && <p>Submitting...</p>}
              {error && <p>Error: {error.message}</p>}
              {showSuccess && (
                <Box sx={{ mt: 1 }}>
                  <Alert
                    variant='outlined'
                    severity='success'
                  >
                    Employee created!
                  </Alert>
                  <Button
                    autoFocus
                    type='button'
                    fullWidth
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      setShowSuccess(false);
                      resetForm();
                      firstNameRef.current.focus();
                    }}
                    disabled={loading}
                    size={'large'}
                    sx={{ mt: 2 }}
                  >
                    Add Another?
                  </Button>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
}
