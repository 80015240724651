import { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import useAuth from '../hooks/useAuth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const [currentUser, setCurrentUser] = useState(null);
  // const [loading, setLoading] = useState(true);
  const { user: currentUser, loading, details } = useAuth();

  // useEffect(() => {
  // const unsubscribe = onAuthStateChanged(auth, (user) => {
  //   setCurrentUser(user);
  //   setLoading(false);
  // });

  // Cleanup the listener on component unmount.
  // return () => unsubscribe();
  // }, []);
  console.log('current user', currentUser);
  console.log('current user role', details);

  return (
    <AuthContext.Provider value={{ ...details }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
