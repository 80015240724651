import { useState, useEffect, useContext } from 'react';
import Layout from './Default/Default';
import { AuthContext } from '../context/AuthContext';

function PrivateRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { verified } = useContext(AuthContext);

  useEffect(() => {
    console.log('privateRoutes useEffect trigger user', verified);
    if (verified) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      window.location.href = '/login';
    }
  }, [verified]);

  if (!isAuthenticated) return null;

  return <Layout />;
}

export default PrivateRoutes;
