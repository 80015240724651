import { useState, useEffect } from 'react';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { db } from '../firebase';
// import { collection, onSnapshot } from "firebase/firestore";

// function useFirestoreCollection(collectionName) {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     setLoading(true);
//     const unsubscribe = onSnapshot(
//       collection(db, collectionName),
//       (snapshot) => {
//         const newData = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
//         setData(newData);
//         setLoading(false);
//       },
//       (err) => {
//         setError(err);
//         setLoading(false);
//       },
//     );

//     // Cleanup listener on unmount
//     return () => unsubscribe();
//   }, [collectionName]);

//   return { data, loading, error };
// }

/**
 * useFirestoreCollection - A hook to get all documents from a collection or sub-collection in Firestore and listen to real-time updates.
 *
 * @param {string} mainCollection - The name of the main collection.
 * @param {string} documentId - (Optional) The ID of the document in the main collection (only needed for sub-collections).
 * @param {string} subCollection - (Optional) The name of the subcollection.
 * @returns {Object} - The collection data, loading state, and any error.
 */
export function useFirestoreCollection(
  mainCollection,
  documentId = null,
  subCollection = null
) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // const db = getFirestore();
    let query;

    console.log('mainCollection', mainCollection);
    console.log('documentId', documentId);
    console.log('subCollection', subCollection);

    if (subCollection && documentId) {
      query = collection(doc(db, mainCollection, documentId), subCollection);
    } else {
      query = collection(db, mainCollection);
    }

    const unsubscribe = onSnapshot(
      query,
      (snapshot) => {
        setLoading(true);
        const allData = [];
        snapshot.forEach((doc) => {
          allData.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setData(allData);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    // Cleanup function to detach the listener when the component is unmounted
    return () => unsubscribe();
  }, [mainCollection, documentId, subCollection]);

  return { data, loading, error };
}

export default useFirestoreCollection;
