import { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import SortIcon from '@mui/icons-material/Sort';
import { capitalizeWords } from '../../../utilities/customText';
import useFirestoreUpdate from '../../../hooks/useFirestoreUpdate';
import { Timestamp } from 'firebase/firestore';
import Button from '@mui/material/Button';
import AddEmployeeModal from '../../popups/AddEmployeeModal';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);

// TODO ned to add filters if an employee does not have an assigned store

const HoursEntryTable = ({ loading, data, selectedStore, selectedDate }) => {
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
  const newHours = useFirestoreUpdate();
  console.log('HoursEntryTable props', {
    loading,
    data,
    selectedStore,
    selectedDate,
  });

  /**
   * component for custom descending icon
   * @returns component for custom descending icon
   */
  const SortedDescendingIcon = () => {
    return <ExpandMoreIcon className='icon' />;
  };

  /**
   * component for custom ascending icon
   * @returns component for custom ascending icon
   */
  const SortedAscendingIcon = () => {
    return <ExpandLessIcon className='icon' />;
  };

  /**
   * component for custom unsorted icon
   * @returns component for custom unsorted icon
   */
  const UnsortedIcon = () => {
    return <SortIcon className='icon' />;
  };

  /**
   * Write workHours to db and return old data
   * @param {Object} newRow the updated value from the data grid
   * @param {Object} oldRow the previous state of the data grid
   * @returns the new row data to display
   */
  const updateHours = (newRow, oldRow) => {
    const workHourId = `${dayjs(selectedDate).year()}${dayjs(
      selectedDate
    ).week()}`;

    const workHoursPath = [
      'stores',
      selectedStore,
      'employees',
      newRow.id,
      'workHours',
      workHourId,
    ];

    newHours.upsertFirestoreDoc(workHoursPath, {
      weekEnding: Timestamp.fromDate(dayjs(selectedDate).toDate()),
      hours: newRow.hours,
    });

    return newRow;
  };

  // const handleAddEmployee = () => {
  //   setOpenAddEmployeeModal(true);
  // };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'end', px: 2, pt: 2 }}>
        {/* <Tooltip title='Add new employee'>
          <Button
            size='small'
            onClick={handleAddEmployee}
            startIcon={<PersonAddAlt1Icon />}
            variant='text'
          >
            Add Employee
          </Button>
        </Tooltip> */}
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  };

  /**
   * grid column def
   */
  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      type: 'string',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      maxWidth: 200,
      valueGetter: ({ value }) => {
        // capitalize first letter
        if (value) return capitalizeWords(value);
        return value;
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      type: 'string',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      maxWidth: 200,
      valueGetter: ({ value }) => {
        // capitalize first letter
        if (value) return capitalizeWords(value);
        return value;
      },
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Name',
    //   type: 'string',
    //   flex: 1,
    //   align: 'center',
    //   headerAlign: 'center',
    //   maxWidth: 200,
    //   valueGetter: (params) => {
    //     return capitalizeWords(
    //       `${params.row.lastName || ''}, ${params.row.firstName || ''}`
    //     );
    //   },
    // },
    {
      field: 'hours',
      headerName: 'Hours',
      type: 'number',
      flex: 1,
      editable: true,
      align: 'center',
      headerAlign: 'center',
      maxWidth: 200,
    },
  ];

  return (
    <>
      <DataGrid
        loading={loading}
        rows={data ? data : []}
        columns={columns}
        columnBuffer={5}
        rowBuffer={5}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'firstName',
                sort: 'asc',
              },
            ],
          },
        }}
        disableSelectionOnClick
        autoHeight
        onProcessRowUpdateError={(error) => {
          console.log('error while updating row', error);
        }}
        processRowUpdate={updateHours}
        slots={{
          toolbar: CustomToolbar,
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <AddEmployeeModal
        open={openAddEmployeeModal}
        handleClose={() => {
          setOpenAddEmployeeModal(false);
        }}
      />
    </>
  );
};

export default HoursEntryTable;
