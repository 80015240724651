import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isWednesday } from '../../../utilities/time/customDateTime';

export default function HoursFilterDatePicker({ onOptionSelected }) {
  const [value, setValue] = useState();
  const [error, setError] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const ref = useRef();

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate':
      case 'minDate': {
        return 'Please select a date in the first quarter of 2022';
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      default: {
        return '';
      }
    }
  }, [error]);

  /**
   * Sets the selected date to the data-grid row
   * @param {object} dayjsObj dayjs object representing the date that was selected on component
   */
  const handleValueChange = (dayjsObj) => {
    console.log('hoursFilterDatePicker handleValueChange dayjs', dayjsObj);

    setValue(dayjsObj);
    onOptionSelected(dayjsObj);
  };

  return (
    <FormControl
      fullWidth
      size='small'
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Select Work Week End Date'
          onError={(newError) => setError(newError)}
          onOpen={() => setOpenCalendar(true)}
          onClose={() => setOpenCalendar(false)}
          // defaultValue={getPreviousSaturday()}
          open={openCalendar}
          slotProps={{
            textField: {
              size: 'small',
              error: false,
              variant: 'outlined',
              helperText: errorMessage,
              readOnly: true,
              disableUnderline: true,
              onClick: () => setOpenCalendar(true),
              /* helperText: 'Please fill this field', */
            },
          }}
          value={value}
          ref={ref}
          disableFuture
          onChange={handleValueChange}
          shouldDisableDate={(dayjsObj) => !isWednesday(dayjsObj)}
          sx={{ width: '100%' }}
          // renderInput={(params) => (
          // <TextField
          // {...params}
          // className="pointAtMe"
          // sx={{
          //   "& .MuiOutlinedInput-root": {
          //     "& fieldset": {
          //       display: "none",
          //     },
          //     "&:hover fieldset": {
          //       display: "none",
          //     },
          //     "&.Mui-focused fieldset": {
          //       display: "none",
          //     },
          //   },
          //   "& .MuiInput-underline:after": {
          //     display: "none",
          //   },
          //   "& .MuiInput-underline:before": {
          //     display: "none",
          //   },
          //   "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
          //     display: "none",
          //   },
          // }}
          // />
          // )}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
