import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

// import useAuth from '../../../../hooks/useAuth';

// import { useEffect, useState } from "react";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Button, Container, Typography } from '@mui/material';

// import useUser from "../../../../hooks/useUser";
import PendingUsersTable from '../../components/tables/PendingUsersTable';
import ReviewedUsersTable from '../../components/tables/ReviewedUsersTable';
import useUserReview from '../../hooks/useUserReview';

const UserManagement = () => {
  // const { details } = useAuth();
  const { pendingUsers, loading, reviewedUsers, error } = useUserReview();

  if (loading) return <CircularProgress />;

  return (
    <Grid
      container
      id='userApprovals'
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Paper sx={{ p: 2, overflow: 'hidden' }}>
          <Box>
            <Typography
              variant='h6'
              gutterBottom
            >
              Pending
            </Typography>
            <Typography
              variant='caption'
              display='block'
              sx={{ mb: 2 }}
              gutterBottom
            >
              Users who are pending approvals
            </Typography>
          </Box>

          <PendingUsersTable
            loading={loading}
            users={pendingUsers}
          />
          {/* Your Admin Dashboard content */}
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Paper sx={{ p: 2, overflow: 'hidden' }}>
          <Box>
            <Typography
              variant='h6'
              gutterBottom
            >
              Roles
            </Typography>
            <Typography
              variant='caption'
              display='block'
              sx={{ mb: 2 }}
              gutterBottom
            >
              Users who have been reviewed and approved will need to get assigned a role.
            </Typography>
          </Box>

          <ReviewedUsersTable
            loading={loading}
            users={reviewedUsers}
          />
          {/* Your Admin Dashboard content */}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserManagement;
