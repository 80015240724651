import { useState, useEffect, useRef } from 'react';
import { db } from '../firebase';
import { produce } from 'immer';
import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);

const useEmployeeHours = () => {
  const [employeeHoursData, setEmployeeHoursData] = useState([]);
  const [storeName, setStoreName] = useState(null);
  const [weekEndingDate, setWeekEndingDate] = useState();
  const [loading, setLoading] = useState(false);
  const debounceTimer = useRef();

  useEffect(() => {
    console.log('useemployee4 useEffect triggered');
    if (!storeName || !weekEndingDate) return;
    console.log('useemployee4 useEffect triggered', [
      storeName,
      weekEndingDate,
    ]);
    setLoading(true);

    // Fetch all employees for the given center
    const unsubscribe = onSnapshot(
      collection(db, 'stores', storeName, 'employees'),
      (snapshot) => {
        const fetchData = async () => {
          const data = await Promise.all(
            snapshot.docs.map(async (emp) => {
              // console.log("emp", emp);
              console.log('emp', emp.data());
              const employeeData = emp.data();

              const hoursRef = doc(
                db,
                'stores',
                storeName,
                'employees',
                emp.id,
                'workHours',
                `${dayjs(weekEndingDate).year()}${dayjs(weekEndingDate).week()}`
              );

              console.log(
                'date',
                `${dayjs(weekEndingDate).year()}${dayjs(weekEndingDate).week()}`
              );

              const hoursDoc = await getDoc(hoursRef);

              return produce(employeeData, (draft) => {
                draft.id = emp.id;
                draft.hours = hoursDoc.exists() ? hoursDoc.data().hours : 0;
              });
            })
          );
          setEmployeeHoursData(_.orderBy(data, ['lastName', 'firstName']));
          setLoading(false);
        };
        clearTimeout(debounceTimer.current);
        debounceTimer.current = setTimeout(fetchData, 400);
      }
    );

    return () => {
      unsubscribe();
      clearTimeout(debounceTimer.current);
    };
  }, [storeName, weekEndingDate]);

  return {
    employeeHoursData,
    setWeekEndingDate,
    setStoreName,
    loading,
  };
};

export default useEmployeeHours;
