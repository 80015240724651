import { object, string, number, date, InferType } from "yup";
import { isSaturday } from "../utilities/time/customDateTime";

export const employeeSchema = {
  created: "",
  firstName: "",
  lastName: "",
  store: "",
};

export const employeesEntryValidationSchema = object({
  created: date().required("Created date is required"),
  firstName: string().required("First name is required").lowercase(),
  lastName: string().required("Last name if required").lowercase(),
  store: string().required("Employed store name is required").lowercase(),
});

export const hoursSchema = {
  date: "",
  hours: 0,
  modified: "",
  user: "",
};

export const hoursDateGridSchema = {
  id: "",
  row: "",
  date: "",
  hours: 0,
  firstName: "",
  lastName: "",
  user: "",
};

export const hoursEntryValidationSchema = object({
  date: date().required("Date is required"),
  hours: number().required("Hours is required").min(0).positive().integer(),
  modified: string().required(),
  user: string().required().uuid(),
});

export const employeeHourSchema = {
  weekEnding: "",
  weeklyHours: "",
};

export const employeeHourSchemaValidationSchema = object({
  weekEnding: date().required("Date is required"),
  weeklyHours: number()
    .required("Hours is required")
    .min(0)
    .positive()
    .integer(),
});

export const storeSchema = {
  name: "",
};

export const storeSchemaValidationSchema = object({
  name: string().required("Name is required"),
});
