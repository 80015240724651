import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import RectangleIcon from '@mui/icons-material/Rectangle';
import './styles.css';
import StoreSelect from '../../components/inputs/StoreSelect/StoreSelect';
import useEmployees from '../../hooks/useEmployees';
import Tooltip from '@mui/material/Tooltip';
dayjs.extend(isoWeek);

const InsuranceTracking = ({ store }) => {
  const [selectedStore, setSelectedStore] = useState();
  const { data, setStoreName, loading } = useEmployees(selectedStore);

  useEffect(() => {
    if (!selectedStore) return;
    setSelectedStore(selectedStore);
  }, [selectedStore, setStoreName]);

  const getRowClass = (params) => {
    const latestWorkHours = params.row.latestWorkHours;
    const workWeeks = _.chain(latestWorkHours)
      .keysIn()
      .orderBy([], ['asc'])
      .value();

    const qualifyingWeeks = workWeeks.map((week) => {
      if (latestWorkHours[week] >= 20) return true;
      return false;
    });

    _.reduce(
      latestWorkHours,
      function (sum, n) {
        return sum + n;
      },
      0
    );

    if (isAllWeeksQualified(qualifyingWeeks) >= 4) return 'bg-success';
    if (
      isAllWeeksQualified(qualifyingWeeks) >= 3 &&
      !isEarlistWeekQualified(qualifyingWeeks)
    )
      return 'bg-warning';
    return '';
  };

  /**
   * Check if all items in work hours in the latestWorkHours 'qualify'
   * @param {Array} qualifyingWeeks array containing the latest work hours for previous weeks
   * @returns integer count for how many 'true' values in array
   */
  const isAllWeeksQualified = (qualifyingWeeks) => {
    return _.chain(qualifyingWeeks)
      .map((week) => {
        if (week) return 1;
        return 0;
      })
      .reduce((sum, n) => sum + n)
      .value();
  };

  const isLastestWeekQualified = (qualifyingWeeks) => {
    const len = qualifyingWeeks.length;
    if (qualifyingWeeks[len - 1]) return true;
    return false;
  };

  const isEarlistWeekQualified = (qualifyingWeeks) => {
    // const len = qualifyingWeeks.length;
    if (qualifyingWeeks[0]) return true;
    return false;
  };

  /**
   * Formats the timestamp format that firebase uses into a normal date format
   * @param {string} param0 the string EPOCH time to convert to regular date
   * @returns the formatted datetime stamp
   */
  const convertTimestamp = ({ value }) =>
    value && new Date(value.seconds * 1000);

  const columns = [
    {
      field: 'lastName',
      headerName: 'Last Name',
      type: 'string',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      type: 'string',
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'latestWorkHours',
      headerName: 'Qualifying Weeks',
      align: 'center',
      headerAlign: 'center',
      flex: 1.5,
      renderCell: (params) => {
        const latestWorkHours = params.row.latestWorkHours;
        const workWeeks = _.chain(latestWorkHours)
          .keysIn()
          .orderBy([], ['asc'])
          .value();
        const bars = workWeeks.map((weeks, index) => {
          console.log('weeks', weeks);
          const tooltip = `${dayjs()
            .isoWeek(weeks.substring(4))
            .day(3)
            .format('MM/DD/YYYY')} : ${latestWorkHours[weeks]} hours`;
          if (latestWorkHours[weeks] >= 20) {
            return (
              <Tooltip title={tooltip}>
                <RectangleIcon
                  key={index}
                  sx={{ color: 'green' }}
                />
              </Tooltip>
            );
          }
          return (
            <Tooltip title={tooltip}>
              <RectangleIcon
                key={index}
                sx={{ color: 'red' }}
              />
            </Tooltip>
          );
        });
        return bars;
      },
    },
  ];

  if (loading)
    return (
      <Grid
        container
        justifyContent='center'
        alignItems='center'
      >
        <CircularProgress />
      </Grid>
    );

  return (
    <Grid
      container
      id='hoursViewer'
      spacing={2}
    >
      <Grid
        item
        xs
      >
        <Paper sx={{ p: 2, overflow: 'hidden' }}>
          <Box>
            <Typography
              variant={'h6'}
              gutterBottom
            >
              Store Selector
            </Typography>
          </Box>
          <StoreSelect onOptionSelected={setSelectedStore} />
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Paper sx={{ p: 2, overflow: 'hidden' }}>
          <Typography
            variant='h6'
            gutterBottom
          >
            Qualified Employees
          </Typography>
          <Typography
            variant='caption'
            display='block'
            sx={{ mb: 2 }}
            gutterBottom
          >
            List all employees qualified statuses
          </Typography>
          <DataGrid
            loading={loading}
            rows={data}
            columns={columns}
            pageSize={5}
            rowLength={5}
            maxColumns={6}
            rowsPerPageOptions={[5]}
            getRowClassName={getRowClass}
            autoHeight
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'lastName',
                    sort: 'asc',
                  },
                ],
              },
            }}
            disableSelectionOnClick
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InsuranceTracking;
